.norecord {
 
  color: #4b5563 !important;
    background: #f9fafb !important;
}
.dropdown-item.active, .dropdown-item:active {
  background-color: transparent !important;
}
.top-bar {
  position: absolute;
  top: 0;
  height: 8vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0px 20px;
  img {
    width: 81.5px;
  }
  .dropdown {
    .dropdown-item-text {
      padding: 12px 16px;
      word-break: break-word;
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;

      span {
        font-family: Inter;
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
      }
    }
    .dropdown-item {
      padding: 8px 16px;

      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      color: #6b7280;
    }
    .dropdown-toggle {
      display: flex;
      justify-content: center;
      align-items: center;
      background: transparent;
      border: 0;
      border-radius: 50%;

      &:active {
        background-color: transparent;
      }
      &::after{
        display: none;
      }
    }
    .dropdown-menu {
      width: 224px;
      padding: 0;
    }
  }
  .user {
    width: 32px;
  }
}

.dashboard-wrapper {
  display: flex;
  align-items: start;
  height: 92vh;
  margin-top: 8vh;
  position: absolute;
  width: 100%;

  .sidebar {
    width: 250px;
    height: 100%;
    border: 1px solid #f3f4f6;
    .list-tab-wrapper {
      padding-bottom: 20px;
      border-bottom: 1px solid #f3f4f6;
      .list-tab {
        display: flex;
        background-color: #f3f4f6;
        padding: 16px 12px;

        font-family: Inter;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;

        .list {
          width: 24px;
          margin-right: 5px;
        }
      }
    }
  }
  .dashboard {
    width: 100%;
    height: 100%;
    .search-box {
      padding: 17px 20px;
      width: 100%;
      border-top: 1px solid #f3f4f6;
      border-bottom: 1px solid #f3f4f6;
      .form-control {
        width: 562.58px;
        height: 42px;
        padding: 12px 16px;

        border-radius: 8px;
        border: 1px solid #f3f4f6;
        background-color: #f9fafb;

        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 17.5px;
        text-align: left;

        &:focus{
          box-shadow: none !important;
        }
      }
    }
    .content-wrapper {
      width: 100%;
      background-color: #f9fafb;
      padding: 15px 40px 0px 20px;
      height: 83%;
      overflow-x: scroll;

      &::-webkit-scrollbar {
        display: none;
      }
      .spinner-wrapper {
        height: 65vh;
        display: flex;
        position: absolute;
        left: 50%;
        justify-content: center;
        align-items: center;
        .spinner-border {
          width: 3rem;
          height: 3rem;
          border-width: 5px;
      }
        
      }
      .table {
        tr {
          border: 0 !important;
          th {
            border-bottom: 0 !important;
            background-color: #f4f4f5;
            text-align: start;
            color: #4b5563;
            font-family: Inter;
            font-size: 14px;
            font-weight: 600;
            line-height: 21px;

            &:last-child {
              text-align: end !important;
            }
          }

          td {
            background-color: #f9fafb;
            border-bottom: 0 !important;
            text-align: start;
            font-family: Inter;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;

            padding: 10px 0.5rem;
            &:last-child {
              text-align: end !important;
            }

            img {
              width: 40px;
              height: 40px;
              border-radius: 8px;
            }
            .view-btn {
              padding: 6px 10px;
              border-radius: 8px;
              background-color: #e5233d;
              color: #ffffff;
              border: 0;

              font-family: Inter;
              font-size: 14px;
              font-weight: 500;
              line-height: 20px;
            }
          }
        }
      }
    }
    .pagination-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #ffffff;
      position: fixed;
      bottom: 0;
      width: 100%;
    width: -webkit-fill-available;
      padding: 10px;
      padding-right: 20px;
      .pagination {
        display: flex;
        align-items: center;
        button {
          border: 0;
        }
        p {
          margin-bottom: 0;
          font-family: Inter;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
        }
        span {
          font-family: Inter;
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;
        }
      }
      .activity {
        display: flex;
        align-items: center;

        p {
          margin-bottom: 0;
          font-family: Inter;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          margin-right: 5px;
        }
        span {
          font-family: Inter;
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;
        }
      }
    }
  }
}
