.modal-content {
  background-color: #000 !important;
}
.modal-header {
  .modal-title {
    color: #fff;
  }
}
.modal-dialog {
  max-width: 1000px !important;
}
.modal-body {
  padding: 24px !important;
}
.btn-close {
  color: #9ca3af;
}
.image-wrapper {
  background-color: #fff;
 

  img {
    width: 100%;
  }
}
.btn-close {
  filter: invert(1);
}
.content-wrapper {
  width: 100%;
  padding-left: 48px;
  h1 {
    font-family: Inter;
    font-size: 36px;
    font-weight: 400;
    line-height: 40px;
    color: #fff;
    margin-bottom: 40px;
  }
  .table-content {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    .firstCol {
      font-family: Inter;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: #fff;
      margin-right: 20px;
      margin-bottom: 0;
      width: 80px !important;
    }
    .secondCol {
      font-family: Inter;
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;
      color: #fff;
      margin-bottom: 0;
    }
  }
}
.row-class {
  align-items: center;
}
.logo-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  img {
    width: 61.13px;
    height: 24;
  }
  .grey-txt {
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    color: #4b5563;
    margin-bottom: 0;
  }
}

.download-btn {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  width: 100%;
  background-color: #e5233d !important;
  border: 0 !important;
  padding: 15px 0 !important;
  display: flex;
  justify-content: center;
text-decoration: none;
align-items: center;
  img {
    margin-left: 12px;
    width: 13px;
    height: 13px;
  }
}
