.login-section {
  height: 100vh;
  background-color: #f3f4f6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .logo {
    width: 81.5px;

    margin-bottom: 50px;
  }
}

.login-form {
  width: 384px;
 
  background-color: #fff;
  padding: 32px;
  font-family: "Inter" !important;
  h1 {
    text-align: start;
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    color: #111928;
  }
  .form {
    margin-top: 20px;
    margin-bottom: 20px;
    label {
      width: 100%;
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      text-align: start;
    }
    .form-control {
      width: 100%;
      color: #6b7280;
      padding: 12px 16px 12px 16px;
      border: 1px solid #d1d5db;
      border-radius: 8px;
    }
    .login-btn {
      padding: 12px 20px;
      border-radius: 8px;
      background-color: #e5233d;
      color: #ffffff;
      border: 0;
      width: 100%;
      font-family: Inter;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }
  }
}
